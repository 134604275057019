import { render, staticRenderFns } from "./pageLeft.vue?vue&type=template&id=87904940&scoped=true&"
import script from "./pageLeft.vue?vue&type=script&lang=js&"
export * from "./pageLeft.vue?vue&type=script&lang=js&"
import style0 from "./pageLeft.vue?vue&type=style&index=0&id=87904940&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "87904940",
  null
  
)

export default component.exports