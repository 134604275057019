<template>
  <div class="left-content">
    <div class="array-type" v-if="btnShow">
      <p class="type-title">排列方式：</p>
      <img
        class="type-icons"
        :src="$imgOnlineUrl + '/equipment/gather/icon2.png'"
        v-show="!single"
        title="单个展示"
        @click="changeType(0)"
      />
      <img
        class="type-icons"
        :src="$imgOnlineUrl + '/equipment/gather/icon1-act.png'"
        v-show="single"
      />
      <img
        class="type-icons"
        :src="$imgOnlineUrl + '/equipment/gather/icon1.png'"
        title="平铺展示"
        v-show="single"
        @click="changeType(1)"
      />
      <img
        class="type-icons"
        :src="$imgOnlineUrl + '/equipment/gather/icon2-act.png'"
        v-show="!single"
      />
    </div>
    <div class="main" v-show="single" v-blockLoadMore="loadMoreVideoData">
      <div
        class="main-items"
        :class="i === myItemsIndex ? 'active' : ''"
        v-for="(t, i) in lists"
        :key="i"
        @click="changeItems(i, t.id, t.alias, t.sizeType)"
      >
        <img :src="config.img" />
        <span class="main-msg">{{ t.title }}</span>
      </div>
    </div>
  </div>
</template>

<script>
// import { number } from "echarts/core";
import { mapState } from "vuex";

export default {
  name: "pageleft",
  props: {
    // 排列方式是否显示
    btnShow: {
      type: Boolean,
      default: true,
    },
    config: {
      type: Object,
    },
    lists: {
      type: Array,
    },
    // itemsIndex: {
    //   type: Number,
    //   default: 0,
    // },
  },
  data() {
    return {
      myItemsIndex: 0,
      single: true,
    };
  },
  watch: {
    equipmentName() {
      this.myItemsIndex = 0;
    },
  },
  created() {
    this.myItemsIndex = 0;
  },
  computed: {
    ...mapState({
      equipmentName: (state) => state.user.equipmentName,
    }),
  },
  methods: {
    loadMoreVideoData() {
      this.$emit("loadMoreVideoData");
    },
    changeType(index) {
      if (index) {
        this.single = false;
        this.$emit("changeAll", 1);
      } else {
        this.single = true;
        this.$emit("changeItems", { id: this.lists[0].id, type: 0 });
      }
    },

    changeItems(index, id, alias, sizeType) {
      this.myItemsIndex = index;
      this.$emit("changeItems", { alias, id, type: 0, sizeType });
    },
  },
};
</script>

<style lang="less" scoped>
.left-content {
  width: 487px;
  height: 800px;
  background: #0e2138;
  display: flex;
  flex-direction: column;
  padding: 49px 50px;
  margin: 0 20px 0 0;
  .array-type {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .type-title {
      font-size: 16px;
      margin-right: 10px;
    }
    img {
      cursor: pointer;
      margin-right: 10px;
    }
    .type-icons {
      width: 30px;
      height: 30px;
    }
  }
  .main {
    width: 100%;
    height: 500px;
    padding: 0 10px 0 0;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 7px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #3e90e5;
    }

    .main-items {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 0 25px;
      height: 38px;
      margin-bottom: 18px;
      background: #0e2f53;
      cursor: pointer;

      &:hover {
        background: #3e90e5;
      }

      &.active {
        background: #3e90e5;
      }

      img {
        width: 27px;
        height: 20px;
        margin-right: 10px;
      }

      .main-msg {
        font-size: 14px;
        color: #fff;
      }
    }
  }
}
</style>
